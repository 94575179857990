import Axios from "axios";
// export let SERVER_URL = "http://192.168.29.198:3001";
// http://staging.sysmatech.com/zydus/public/api/zydus/assetDetails/63

// export let SERVER_URL = "https://staging.sysmatech.com";
export let SERVER_URL = "https://uat.sysmatech.com"; // UAT
// export let SERVER_URL = "https://coolkit.syskode.com"; // Cool Kit
// export let PUBLIC_URL = `${SERVER_URL}/zydus/public/api/zydus`;
// export let PUBLIC_URL = `${SERVER_URL}/public/api/uat`; // UAT
export let PUBLIC_URL = `${SERVER_URL}/admin/public/api`; // UAT

export async function dynamicBaseUrl(client) {
  try {
    const res = await Axios.get(
      `https://staging.sysmatech.com/zydus/public/api/v1/public/token-by-client/${client}`
    );
    return res;
  } catch (error) {
    return error;
  }
}

export async function axiosRequest(method, url, data = null, prefixUrl = null) {
  let custom_url = PUBLIC_URL;
  let preToken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiOTQ2MGFjZGY0NGRmYzBhNzdhMzY3ZGM1Y2U2OTFhMzRiMjkxZDJmNzZmMWRhODc1ZjA4YjVmMTU0MDliODg5ZTM0MWZjMTMyZWFjMWNiOTkiLCJpYXQiOjE2NTg0MDIzOTEsIm5iZiI6MTY1ODQwMjM5MSwiZXhwIjoyMTMxNzg3OTkxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.PBYj0IxKsR_6ywiRj5xwSKBS9p0scxmw17LpQDUQmqGwOit3axa8hgAzjPeHTI1VpkchKIcUSYhOIv_TZeQrmvjJN0xPt3hXhQP7OByrM_46oGfUkUx82BwPtfY40hO2bAIsERfF4mn1kA_pHCpV9z8aKkV8QQE3iPTLh-f56PlVDioczlDjqH7_fGSwiz1tjnF8g47ynvEG07ZoBp2f2BpgIm-NJUeed-DrRxfwHOcbzm9cVF9TNjSyIg_T503G-ZVBF1JgSpTQN1FpL6t2zkxNEdTnVR98aWXuMjwqGrR9M07w9lwDSCvymtXS_9qgi33cFoHFTeUi83brT-4ZsEIpRLtZzWWKO3EHAnqtRXoMGz7qSzneO57RBDxcudisJb9D9P3de0z8tXZWo-yl5h7TAS4qvwCaF519N3eU9zUnHGcRlIGEB8Z-gqe3f1nGgHppzN9K1l0B3aM3ixFHvxL3Vvyugl0JIt9c_3f8y86TcMRi-C7Z9hxvztPXmnBuCU7wN8xYjYGV3K1ITKGOx4MYfa5VCoJUNSkkvQJx_nCy6SXXqFaiekTCMiCtglhBN-WGAk_yOoCuQUmlK9ifa-uv-t95A-IJg_s1U8dPEg2f544bz-AJOcjTsPaLyAv1_jeMA_wWqXNSwOd67u6WYsTTdzuYcGW1FkCewSub-mA";

  // preToken = ''

  if (prefixUrl) {
    custom_url = prefixUrl.endpoint;
    preToken = prefixUrl.token;
  }

  try {
    const token = `Bearer ${prefixUrl.token}`;
    let response = await Axios({
      method: method,
      url: `${custom_url}${url}`,
      headers: {
        Authorization: token,
        accept: "application/json",
        credentials: "include",
        "Content-Type": "application/json",
      },
      data: data,
    });
    return await response.data;
  } catch (error) {
    return error;
  }
}
