import { axiosRequest } from "../helper";

export const getCompanyLogoApi = async (prefixUrl) => {
  const companyLogo = await axiosRequest(
    "GET",
    "/get_company_logo",
    {},
    prefixUrl
  );
  return companyLogo;
};

export const getAssetsDetailsApi = async (id, prefixUrl) => {
   const assetDetail = await axiosRequest(
    "GET",
    `public/asset/${id}`,
    {},
    prefixUrl
  );
  // const assetDetail =  await axiosRequest("POST", `/assetDetails/${id}`);
  return assetDetail;
};

export const getAssetsCauseApi = async (id, prefixUrl) => {
  return await axiosRequest(
    "GET",
    `public/asset/${id}/causes/questions`,
    {},
    prefixUrl
  );
};

export const inspactAuthorizationApi = async (phone, company_id, prefixUrl) => {
  return await axiosRequest(
    "POST",
    `/login?mobile=${phone}&company_id=${company_id}`,
    {},
    prefixUrl
  );
};

export const getAssetsScheduleApi = async (
  user_id,
  company_id,
  asset_id,
  prefixUrl
) => {
  return await axiosRequest(
    "POST",
    `/get_my_asset_type_schedule?user_id=${user_id}&company_id=${company_id}&asset_id=${asset_id}`,
    {},
    prefixUrl
  );
};

export const inspectionFormSubmitApi = async (data, prefixUrl) => {
  return await axiosRequest(
    "POST",
    `/submit_inspection_info`,
    {
      ...data,
    },
    prefixUrl
  );
};

export const submitCausesApi = async (data, prefixUrl) => {
  return await axiosRequest(
    "POST",
    `public/maintenance/workorder`,
    { ...data },
    prefixUrl
  );
};

export const imageUploadApi = async (data, prefixUrl) => {
  console.log("data1", typeof(data));
  return await axiosRequest("POST", `/v1/files/upload`, data);
};
